import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { ThemeProvider } from "next-themes";

import type { AppProps } from "next/app";
import "../styles/globals.css";

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: Session }>) {
  return (
    <ThemeProvider
      enableSystem={true}
      attribute="class"
      defaultTheme="light"
      themes={["light", "dark"]}
    >
      <SessionProvider session={session}>
        {/* @ts-ignore */}
        <Component {...pageProps} />
      </SessionProvider>
    </ThemeProvider>
  );
}
